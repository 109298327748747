*,
:after,
:before {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Ubuntu', 'Roboto', 'Helvetica Neue',
    'Arial', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  display: flex;
  line-height: 1;
  min-height: 100%;
  font-weight: 400;
  position: relative;
  letter-spacing: 0px;
  font-size: 0.875rem;
  flex-direction: column;
  touch-action: manipulation;
  text-rendering: optimizelegibility;
}

#root {
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  min-height: 100%;
  flex-direction: column;
}